import React, { useEffect, useRef, useState } from "react";
import { RichText, Image } from "@sitecore-jss/sitecore-jss-react";
import Slider from "react-slick";
import { calculateSpacing } from "../../../../utils/getContainerClasses";

const SocHearingLevel = ({ fields, progress }) => {
  const featureTypes = ["L1", "L2", "L3", "L4", "L5", "L6", "L7"];
  const sliderRef = useRef();
  const activeSlideIndex = useRef();
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    let newActiveIndex = 0;
    const sliderLength = featureTypes.length;
    [...featureTypes, 1].some((_, index) => {
      if (progress <= index / sliderLength) {
        newActiveIndex = index - 1;
        return index;
      }
    });
    if (newActiveIndex !== activeSlideIndex.current) {
      sliderRef.current.slickGoTo(newActiveIndex);
      activeSlideIndex.current = newActiveIndex;
    }
  }, [progress]);

  return (
    <div
      className={`component soc-hearing-level ${fields?.["css classes"]?.value} ${calculateSpacing(fields)}`}
    >
      <Slider {...settings} ref={sliderRef} className="soc-slick-slider">
        {featureTypes.map((item, index) => (
          <div key={item}>
            <div className={`row g-6 mt-0 feature`}>
              <div className="col-12 col-md-6 offset-md-2 d-flex align-items-center mt-0">
                <div className="feature-bar">
                  <span
                    className={`bar ${index <= 0 ? "active" : ""} bar-35`}
                  ></span>
                  <span
                    className={`bar ${index <= 1 ? "active" : ""} bar-68`}
                  ></span>
                  <span className={`bar ${index <= 2 ? "active" : ""}`}></span>
                  <span
                    className={`bar ${index <= 3 ? "active" : ""} bar-68`}
                  ></span>
                  <span
                    className={`bar ${index <= 4 ? "active" : ""} bar-30`}
                  ></span>
                  <span
                    className={`bar ${index <= 5 ? "active" : ""} bar-35`}
                  ></span>
                </div>

                <Image className="feature-image" field={fields.image} />

                <div className="feature-bar">
                  <span
                    className={`bar ${index <= 5 ? "active" : ""} bar-55`}
                  ></span>
                  <span
                    className={`bar ${index <= 4 ? "active" : ""} bar-35`}
                  ></span>
                  <span
                    className={`bar ${index <= 3 ? "active" : ""} bar-68`}
                  ></span>
                  <span className={`bar ${index <= 2 ? "active" : ""}`}></span>
                  <span
                    className={`bar ${index <= 1 ? "active" : ""} bar-68`}
                  ></span>
                  <span
                    className={`bar ${index <= 0 ? "active" : ""} bar-30`}
                  ></span>
                </div>
              </div>
              <div className="col-12 col-md-4 d-flex align-items-center mt-0">
                <div className="feature-explanation">
                  <RichText
                    className="title"
                    field={fields[item + " - Title"]}
                  />
                  <RichText
                    className="sub-title"
                    field={fields[item + " - Subline"]}
                  />
                  <RichText
                    className="description"
                    field={fields[item + " - Description"]}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className={`time-to-act`}>
        <RichText className="content lh-base text-center" field={fields.app} />
      </div>
      
    </div>
  );
};

export default SocHearingLevel;